import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ProjectHeader from "../components/project-header"
import ProjectBlurb from "../components/project-blurb"
import Img from "gatsby-image"

const info = {
    linksOut: [
        {
            text: "Read more about this project",
            href: "https://www.wsdot.wa.gov/sites/default/files/2017/10/10/OEO-Puget-Sound-Gateway-Program-2019-Q2-Diversity-Inclusion-Report.pdf#page=2",
        }
    ]
}

const Page = () => {
    const data = useStaticQuery(graphql`
      query {
        header: file(relativePath: { eq: "project-header--wsdot.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mapp: file(relativePath: { eq: "map--wsdot.png" }) {
          childImageSharp {
            fluid(maxWidth: 680) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        extra: file(relativePath: { eq: "extra--wsdot.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1040) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
    console.log(data)
    return (
        <Layout hideMenu={true}>
          <SEO title="Gateway Completion Project" />
          <ProjectHeader
            client="WSDOT"
            project="Gateway Completion Project"
            image={data?.header?.childImageSharp?.fluid}
          />
          <ProjectBlurb data={info} mapImage={data.mapp.childImageSharp.fluid}>
            <p>
              This project involves extending SR-509 to ease
              congestion on I-5, adding a southern access point to
              Sea-Tac International Airport and improve service
              between industrial districts by allowing general-purpose
              traffic and trucks to bypass I-5, SR 99 and local
              streets.  When finished, SR 509 will become a key
              component of the Seattle and South King County
              transportation network. RES Group NW managed the overall
              relocation process while actively performing 120
              relocations for non-residential and residential
              displacees.  In addition, negotiated full and partial
              acquisitions.  Property rights were acquired in
              accordance with the Federal Uniform Relocation
              Assistance and Real Property Acquisitions Policies Act
              (URA) and WSDOT Right of Way Manual.
            </p>
          </ProjectBlurb>
          <Img
            className="extraMedia"
            alt="Project description"
            fluid={data.extra.childImageSharp.fluid} />
        </Layout>
    )
}

export default Page
